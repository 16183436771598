import NewsSchema from '@atoms/schemas/newsSchema';
import Hero from '@organisms/Hero/Hero';
import Sections from '@organisms/Sections/Sections';
import { NewsPage } from '@type-declarations/page';
import { getHeroColor } from '@utils/getHeroColor';
import { useMemo } from 'react';

export default function NewsTemplate({ page }: { page: NewsPage }) {
  const { id, title, introText, image, tags, alignment } = page;

  const heroColor = useMemo(() => getHeroColor({ page }), [page]);

  return (
    <>
      <Hero
        pageId={id}
        title={title}
        text={introText}
        image={image}
        tags={tags}
        alignment={alignment}
        containerSize="narrow"
        colorVariant={heroColor}
        showTopBar
      />
      <Sections key={id} sections={page.sections} />

      <NewsSchema page={page} />
    </>
  );
}
