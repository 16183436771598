import { ROUTES } from '@constants/constants';
import useStore from '@hooks/useStore';
import AccessibilityButton from '@molecules/Accessiblity/AccessibilityButton.module';
import CookieDeclarationSection from '@organisms/CookieDeclarationSection/CookieDeclarationSection';
import Hero from '@organisms/Hero/Hero';
import Sections from '@organisms/Sections/Sections';
import {
  ContentPage,
  MainCategoryPage,
  SubCategoryPage,
  SubSubCategoryPage,
} from '@type-declarations/page';
import { getHeroColor } from '@utils/getHeroColor';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

function ContentTemplate({
  page,
}: {
  page: ContentPage | MainCategoryPage | SubCategoryPage | SubSubCategoryPage;
}) {
  const { asPath } = useRouter();
  const {
    store: { locale },
  } = useStore();

  const { id, title, introText, image, alignment, heroContent } = page;

  const heroColor = useMemo(() => getHeroColor({ page }), [page]);

  return (
    <>
      {asPath === `/${ROUTES.a11y[locale]}` && <AccessibilityButton />}

      <Hero
        showTopBar
        pageId={id}
        title={title}
        text={introText}
        image={image}
        colorVariant={heroColor}
        alignment={alignment}
      >
        {heroContent.length && (
          <Sections sections={heroContent} modifier="heroSection" />
        )}
      </Hero>

      {asPath === `/${ROUTES.CookieDeclarationPage[locale]}` && (
        <CookieDeclarationSection />
      )}

      <Sections key={id} sections={page.sections} />
    </>
  );
}

export default ContentTemplate;
